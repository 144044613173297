<script>
import Layout from "../../layouts/auth";
import { mapState, mapActions } from "vuex";
import Languages from "@/components/widgets/languages";
import { api } from "@/state/services";

export default {
  locales: {
    pt: {
      'Validating email': 'Validando Email',
      'Try Again': 'Tente novamente',
      'Could not validate email, please try again.': 'Não foi possível validar seu e-mail, tente novamente.',
      'Your e-mail has been successfully validated!': 'Seu e-mail foi válidado com sucesso!'
    },
    es: {
      'Validating email': 'Validando correo electrónico',
      'Try Again': 'Inténtalo de nuevo',
      'Could not validate email, please try again.': 'No se pudo validar el correo electrónico, inténtelo de nuevo.',
      'Your e-mail has been successfully validated!': 'Su correo electrónico ha sido validado exitosamente'
    }
  },
  components: { Layout, Languages },
  data() {
    return {
      loading: true,
      key: this.$route.params.token,
      emailValidation: false
    };
  },
  computed: {
    ...mapState({
      account: state => state.account
    })
  },
  methods: {
    ...mapActions("account", ["token"]),
    getToken() {
      const { key } = this;
      this.token({ key })
    },

    validateEmail() {
      this.loading = true
      api
        .post('user/email/confirmation', {
          token: this.key
        })
        .then(response => {
          if (response.data.status == 'success') {
            this.$store.state.account.user.email.status = 'validated'
            this.emailValidation = true
            this.loading = true
          } else {
            this.loading = true
          }
        });
          this.loading = false
    },
  },
  mounted() {
    this.getToken();
    this.validateEmail();
  }
};
</script>

<template>
  <Layout>
    <div id="auth" class="row justify-content-center login-midlle-md">
      <div class="col-md-6 col-lg-5 col-xl-4 p-0">
        <div class="pt-5 pb-5 text-center">
          <router-link class="align-middle" tag="a" to="/">
            <img class="w-100" style="max-width:250px;" src="@/assets/images/logo-orbyy.png" />
          </router-link>
        </div>
        <div class="pt-5 text-center" v-if="loading">
          <div v-if="emailValidation">
            <h5 show variant="success">{{ t('Your e-mail has been successfully validated!') }}</h5>
          </div>
          <div v-else>
            <h5 show variant="primary">{{ t('Could not validate email, please try again.') }}</h5>
          </div>
        </div>
         <div class="d-flex align-items-center justify-content-center" v-else>
          <span aria-hidden="true" class="m-2 spinner-border text-dark"></span>
        </div>
        <div class="text-center" v-if="loading">
            <Languages type="dropup"></Languages>
        </div>

      </div>
    </div>
  </Layout>
</template>
